import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import img from "../../assets/img.png";

import snapchat from "../../assets/snapchat.png";
import tiktok from "../../assets/tiktok.png";
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/instagram.png";


class Intro extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        navigate: false,
        load: true,
    }
    
    handleChange = lang => {
        this.props.handleLangaugeSubmit(lang);
        this.setState({ navigate : true});
    }

    render() {
        return (
            <>
                { this.state.navigate && <Navigate to="/menu" replace={true} /> }

                {/* {
                    this.state.load && <div className='page-load'>
                                            <img className='logo' src={this.settings.logo} alt="" />
                                        </div>
                } */}


                <div className='intro' style={ { backgroundImage :`url(${this.settings.introCover})` }}>
                    <div className="head">
                        <img src={this.settings.logo} alt="" />
                        {/* <h3>{this.settings.name}</h3> */}
                    </div>
                    <form>
                        <div role='button' className='langBtn' onClick={() => this.handleChange('krd')}>
                            <img src={img} alt="" />
                            <span>کوردی</span>
                        </div>

                        <div role='button' className='langBtn' onClick={() => this.handleChange('ar')}>
                            <img src={img} alt="" />
                            <span>العربیة</span>
                        </div>

                        <div role='button' className='langBtn' onClick={() => this.handleChange('en')}>
                            <img src={img} alt="" />
                            <span>English</span>
                        </div>
                    </form>

                    <div className="social">

                        { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.instgram}><img src={instagram} alt="" /></a>
                        }

                        { 
                            this.settings.snapchat
                            &&
                            <a href={this.settings.snapchat}><img src={snapchat} alt="" /></a>
                        }

                        { 
                            this.settings.facebook
                            &&
                            <a href={this.settings.facebook}><img src={facebook} alt="" /></a>
                        }

                        { 
                            this.settings.tiktok
                            &&
                            <a href={this.settings.tiktok}><img src={tiktok} alt="" /></a>
                        }

                    </div>

                    <div className="info">

                        { 
                            this.settings.phone
                            &&
                            <p><i className="fa-solid fa-phone"></i>Pizza: {this.settings.phone}</p>
                        }

                        { 
                            this.settings.phone2
                            &&
                            <p><i className="fa-solid fa-phone"></i>Doner: {this.settings.phone2}</p>
                        }

                        { 
                            this.settings.address
                            &&
                            <p><i className="fa-solid fa-location-dot"></i>{this.settings.address}</p>
                        }

                        
                    </div>

                    <div className='send-feedback'>
                        <Link to={"/feedback"}>
                            <i className="fa-solid fa-message"></i> Feedback 
                        </Link>
                    </div>

                </div>
            </>
        );
    }
}

export default Intro;