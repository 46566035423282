import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CatLoading from './catloading';
import img from '../../assets/img.png';

class Category extends Component {

    data = this.props.Data;
    language = this.props.Language;

    render() {
        return (
            <div className={`categories-item ${this.props.Active?'active':''}`} onClick={ () => this.props.handleCategory(this.data.id)}>
                <CatLoading Img={img} />
                <span>
                    {
                        this.props.Language == "en" ? this.data['name_eng']
                        : this.props.Language == "krd" ? this.data.name_krd
                        : this.data.name_ar
                    }
                </span>
            </div>
        );
    }
}

export default Category;