import React, { Component } from 'react';
import img6 from '../../assets/img6.png';
import img7 from '../../assets/img7.png';


export default class ImageView extends Component {

  render() {
    const {Data} = this.props;
    return (
        <div
            className={`image-view-container ${this.props.Show? '': 'image-view-container-hide'}`}
            onClick={() => this.props.setViewImage(false)}
            >
            <div className={`image-view ${this.props.Show? 'image-view-show': ''}`}>
                
                <div className='item-bg-large'>
                    <img className='item-bg-head' src={img6} alt="" />
                    <img className='item-bg-content' src={img7} alt="" />
                    <img className='item-bg-foot' src={img6} alt="" />
                </div>
                
                <img className='content-img' src={Data.image} alt="" />
                <div className='image-view-info'>
                    <h5>
                        {
                            this.props.Language == "en" ? Data.name_eng
                            : this.props.Language == "krd" ? Data.name_krd
                            : Data.name_ar
                        }          
                    </h5>
                  <div>{Data.price}</div>
                </div>
            </div>
        </div>
    )
  }
}
