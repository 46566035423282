import React, { Component } from 'react';
import Imgloading from './itemloading';
import Swal from 'sweetalert2';
import img2 from '../../../assets/img2.png';
import img4 from '../../../assets/img4.png';
import img5 from '../../../assets/img5.png';
import ImageView from '../ImageView';


class Two extends Component {

    data = this.props.Data;
    language = this.props.Language;

    state = {
        itemExpand: false,
        viewImage: false,
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({itemExpand: true});
        }, 500)
    }

    showImage = (img) => {

        this.setState({viewImage: true});

        // Swal.fire({
        //     imageUrl: img,
        //     imageAlt: 'image',
        //     backdrop:false,
        //   })
    }

    setViewImage = (val) => {
        this.setState({viewImage: val});
    }

    priceText(text){
        if(text == 'small')
            
            return (
                <span> 
                    {
                        this.props.Language == "en"? 'small'
                        : this.props.Language == "krd"? 'بچیک'
                        : 'صغیر'
                    } 
                </span>
            )
        else if(text == 'medium')
            return (
                <span>
                    {
                        this.props.Language == "en"? 'medium'
                        : this.props.Language == "krd" ? 'ناڤنجی'
                        : 'متوسط'
                    }
                </span>
            )
        else
            return <span>
                {
                    this.props.Language == "en" ? 'large'
                    : this.props.Language == "krd" ? 'مەزن'
                    : 'کبير'
                }
            </span>
    }

    render() {
        return (
            <>
                <div className={`item${this.state.itemExpand? " item-expand": ""}`}>
                    <div className='item-bg-two'>
                        <img className='item-bg-head' src={img4} alt="" />
                        <img className='item-bg-content' src={img5} alt="" />
                        <img className='item-bg-foot' src={img4} alt="" />


                        <div className="image" onClick={ () => this.showImage(this.data.image) }>
                        <Imgloading Img={this.data.image} />
                        {/* <span className="item-order"> {this.data.order??0} </span>  */}
                    </div>
                    <div className="info" onClick={ () => this.showImage(this.data.image) }>
                        <h5>
                            {
                                this.props.Language == "en" ? this.data.name_eng
                                : this.props.Language == "krd" ? this.data.name_krd
                                : this.data.name_ar
                            } 
                        </h5>
                        <div className='price'> 
                        {
                            (this.data.price_small && this.data.price_max)? 
                                <>
                                    
                                    <span> 
                                        { this.priceText('small') } 
                                        {this.data.price_small} 
                                    </span>
                                    <span> 
                                        { this.priceText('medium') } 
                                        {this.data.price} 
                                    </span> 
                                    <span> 
                                        { this.priceText('big') }
                                        {this.data.price_max} 
                                    </span> 
                                </>
                            :
                            (this.data.price_small)?
                            <>
                                
                                <span> 
                                    { this.priceText('small') }
                                    {this.data.price_small} 
                                </span>

                                <span> 
                                    { this.priceText('medium') } 
                                    {this.data.price} 
                                </span> 

                            </>
                            :
                            (this.data.price_max)?
                            <>
                                <span> 
                                    { this.priceText('medium') }  
                                    {this.data.price} 
                                </span> 
                                <span> 
                                    { this.priceText('big') }
                                    {this.data.price_max} 
                                </span> 
                            </>
                            :
                            <span> {this.data.price}  </span> 

                        }
                        </div>
                    </div>

                    </div>
                </div>

                <ImageView 
                            Data = {this.data}
                            Language = {this.props.Language }
                            Show = {this.state.viewImage}
                            setViewImage = {this.setViewImage} />
            </>
        );
    }
}

export default Two;